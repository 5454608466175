.welcome {
    color: white;
    width: auto;
    font-size: x-large;
    font-weight: bold;
    text-align: left;
    padding-top: 10vh;
  }

  #greeting {
    font-weight: normal;
  }

  #nameline {
    margin-top: 0px;
    margin-bottom: 0px;
  }