.links {
    font-size: x-large;
    color: white;
    text-align: left;
  }

  .links a:link {
    color: white;
  }
  
  .links a:visited {
    color: white;
  }