body {    
    margin: 0 !important;
}

.app {
	height: 100%;
    padding: 0 10% 0 10%;
}

text span {
    display: block;
}