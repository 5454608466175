@font-face {
    font-family: "DM Sans";
    src: url('./fonts/DMSans-Regular.ttf')
}

* {
    font-family: 'DM Sans', sans-serif;
}

body {
    background-color: rgb(22, 22, 22);
}