.project {
    font-weight: normal;
}

#title {
    text-decoration: underline;
}

a:link {
    color: white;
}
  
a:visited {
    color: white;
}